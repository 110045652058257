function MapComponent(props){
    if(props.mapview){
        const url="https://www.google.com/maps/embed/v1/place?key=AIzaSyBohem4NYAiffFJlxdr09zWiz5iHT9av6E&zoom=13&q="+props.lat+','+props.lon;
        return(
            <>
                <iframe
                    title="location map"
                    className="map-location"
                    loading="lazy"
                    frameBorder="0" 
                    referrerPolicy="no-referrer-when-downgrade"
                    allowFullScreen
                    src={url}>
                </iframe>
            </>
        )
    }
    else{
        //const url='https://www.google.com/maps/embed/v1/streetview?key=AIzaSyBohem4NYAiffFJlxdr09zWiz5iHT9av6E&location='+props.lat+','+props.lon+'&heading=210&pitch=10&fov=35'
        return(
            <>
                <iframe
                    title="location streetview"
                    className="map-streetview"
                    loading="lazy"
                    frameBorder="0" 
                    referrerPolicy="no-referrer-when-downgrade"
                    allowFullScreen
                    src={props.streetViewSrc}>
                </iframe>
            </>
        )
    }
}

export default MapComponent;